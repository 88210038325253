import React from "react";
import * as styles from "./Pagination.module.scss";
import { cn } from "../../../utils";
import PaginationI from "./Pagination.type";
import { usePagination } from "../../../utils/usePagination";
import { DOTS } from "../../../utils/constants";

const Pagination = ({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
}: PaginationI) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (paginationRange) {
    if (currentPage === 0 || paginationRange.length < 2) {
      return null;
    }

    const onNext = () => {
      onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
      onPageChange(currentPage - 1);
    };

    let lastPage = paginationRange[paginationRange.length - 1];
    return (
      <ul className={styles.pagination}>
        <span
          className={cn([
            styles.btn,
            styles.btnPrev,
            currentPage === 1 ? styles.disabled : "",
          ])}
          onClick={onPrevious}
        />
        {paginationRange.map((pageNumber, index) => {
          if (pageNumber === DOTS) {
            return (
              <span key={index + 1} className={cn([styles.link, styles.dots])}>
                &#8230;
              </span>
            );
          }

          return (
            <li
              className={cn([
                styles.link,
                pageNumber === currentPage ? styles.current : "",
              ])}
              key={index + 1}
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </li>
          );
        })}
        <span
          className={cn([
            styles.btn,
            styles.btnNext,
            currentPage === lastPage ? styles.disabled : "",
          ])}
          onClick={onNext}
        />
      </ul>
    );
  } else {
    return <></>;
  }
};

export default Pagination;
