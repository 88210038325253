import React from "react";
import * as styles from "./SectionTwoBlock.module.scss";
import HeaderTitle from "../../HeaderTitle/HeaderTitle";
import SectionTwoBlockI from "./SectionTwoBlock.type";
import BlockList from "../../BlockList/BlockList";
import { cn } from "../../../utils";

const SectionTwoBlock = ({
  title_block_1,
  title_block_2,
  items_block_1,
  items_block_2,
  background_block_1,
  background_block_2,
}: SectionTwoBlockI) => {
  const items = [
    {
      title: title_block_1,
      items: items_block_1,
      background: background_block_1,
    },
    {
      title: title_block_2,
      items: items_block_2,
      background: background_block_2,
    },
  ];
  return (
    <div className={styles.element}>
      {items.map((item, index) => (
        <div
          key={index}
          className={cn([
            styles.block,
            item.background ? styles.background : "",
          ])}
        >
          <HeaderTitle
            type={"section"}
            title={item.title.title}
            titleColor={item.title.title_color}
            secondTitle={item.title.second_title}
            secondTitleColor={item.title.second_title_color}
            description={item.title.description}
            secondLine={item.title.second_line}
            classNameText={styles.description}
            classNameTitle={styles.title}
          />
          {item.items ? <BlockList items={item.items} full={true} /> : ""}
        </div>
      ))}
    </div>
  );
};

export default SectionTwoBlock;
