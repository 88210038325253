import React from "react";
import PrevArrowI from "./PrevArrow.type";
import { cn } from "../../../../utils";
import * as styles from "./PrevArrow.module.scss";

const PrevArrow = ({ className, onClick }: PrevArrowI) => {
  return (
    <div
      className={cn([styles.element, className ? className : ""])}
      onClick={onClick}
    />
  );
};

export default PrevArrow;
