import React from "react";
import * as styles from "./SectionBuy.module.scss";
import HeaderTitle from "../../HeaderTitle/HeaderTitle";
import TileBuy from "../../TileBuy/TileBuy";
import SectionBuyI from "./SectionBuy.type";
import { cn } from "../../../utils";

const SectionBuy = ({
  title,
  items,
  background = true,
  setModal,
}: SectionBuyI) => {
  return (
    <div className={cn([styles.element, background ? styles.background : ""])}>
      <HeaderTitle
        type={"section"}
        title={title.title}
        titleColor={title.title_color}
        secondTitle={title.second_title}
        secondTitleColor={title.second_title_color}
        description={title.description}
        center={true}
        secondLine={title.second_line}
      />
      <div className={styles.tiles}>
        {items.map((item, index) => (
          <TileBuy
            key={index}
            title={item.title}
            price={item.price}
            discountText={item.discountText}
            patch={item.patch}
            setModal={setModal}
            sellingPoints={item.sellingPoints}
          />
        ))}
      </div>
    </div>
  );
};

export default SectionBuy;
