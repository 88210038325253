import React from "react";
import * as styles from "./SectionCompanies.module.scss";
import HeaderTitle from "../../HeaderTitle/HeaderTitle";
import SectionSlider from "../SectionSlider/SectionSlider";
import SectionCompaniesI from "./SectionCompanies.type";
import { GatsbyImage } from "gatsby-plugin-image";

const SectionCompanies = ({ title, items, companies }: SectionCompaniesI) => {
  return (
    <div className={styles.element}>
      <HeaderTitle
        type={"section"}
        title={title.title}
        secondTitle={title.second_title}
        titleColor={title.title_color}
        secondTitleColor={title.second_title_color}
        center={true}
        description={title.description}
        secondLine={title.second_line}
        classNameTitle={styles.title}
        classNameText={styles.description}
      />
      <SectionSlider items={items} />
      {companies && (
        <div className={styles.companies}>
          {companies.map((company, index) => (
            <div key={index} className={styles.wrapperImg}>
              <a href={company.slug ? company.slug : "#"}>
                <GatsbyImage
                  image={company.icon.gatsbyImageData}
                  alt={company.icon.title}
                  className={styles.img}
                  key={index}
                />
              </a>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SectionCompanies;
