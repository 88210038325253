// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var element = "SectionRecruit-module--element--3AgNS";
export var justworks = "SectionRecruit-module--justworks--3vWmj";
export var preference = "SectionRecruit-module--preference--3eIen";
export var justworks_top_bar = "SectionRecruit-module--justworks_top_bar--39yFr";
export var title = "SectionRecruit-module--title--3vFa3";
export var background = "SectionRecruit-module--background--2gkys";
export var headerTitle = "SectionRecruit-module--headerTitle--2ZMsK";
export var description = "SectionRecruit-module--description--1cVXU";
export var columns = "SectionRecruit-module--columns--2A_28";