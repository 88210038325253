import React, { useEffect, useState } from "react";
import * as styles from "./SectionCountries.module.scss";
import Button from "../../Button/Button";
import TileBlock from "../../TileBlock/TileBlock";
import { cn } from "../../../utils";
import SectionCountriesI from "./SectionCountries.type";
import { Link } from "gatsby";
import countriesList from "../../../assets/json/countries.json";

const SectionCountries = ({ items, setModal, images }: SectionCountriesI) => {
  const [open, setOpen] = useState(false);
  const [countries, setCountries] = useState(countriesList);
  const [query, setQuery] = useState("");
  const [result, setResult] = useState<any>([]);

  const search = (event: React.ChangeEvent<HTMLInputElement>) => {
    const _query = event.target.value;
    const foundItems = countries.filter(item =>
      item.name.toLowerCase().includes(_query.toLowerCase())
    );
    setResult(foundItems);
    setQuery(_query);
  };
  const handlesBlur = () => {
    const interv = setInterval(() => setOpen(false), 100);
    setInterval(() => {
      clearInterval(interv);
    }, 200);
  };
  useEffect(() => {
    const arr = [];
    countriesList.map(country => {
      const iconUrl = images.filter(
        image => image.node.name === country.code.toLowerCase()
      );
      arr.push({
        name: country.name,
        code: country.code,
        icon: iconUrl[0] ? iconUrl[0].node.publicURL : "",
        inList: items.filter(item => item.text === country.name).length != 0,
      });
    });
    setCountries(arr);
    setResult(arr);
  }, []);
  return (
    <div className={styles.element}>
      <div className={cn([styles.search, open ? styles.open : ""])}>
        <input
          value={query}
          className={styles.inputSearch}
          type="search"
          placeholder="Search for the country"
          onFocus={() => setOpen(true)}
          onBlur={handlesBlur}
          onChange={search}
        />
        <Button
          className={styles.button}
          text="Search"
          color={"blue"}
          size={"small"}
        />
        <ul
          id={"searchResult"}
          className={cn([styles.searchResult, open ? styles.open : ""])}
        >
          {result &&
            result.map((item: any, index: any) =>
              item.inList ? (
                <Link key={index} to={`/eor/#${item.name.toLowerCase()}`}>
                  <li key={index} className={styles.searchResultField}>
                    <img
                      className={styles.iconCountry}
                      src={item.icon}
                      alt={item.code.toLowerCase()}
                      width={40}
                    />
                    <span className={styles.nameCountry}>{item.name}</span>
                  </li>
                </Link>
              ) : (
                <div
                  style={{ cursor: "pointer" }}
                  key={index}
                  onClick={() => {
                    setModal(true);
                  }}
                >
                  <li key={index} className={styles.searchResultField}>
                    <img
                      className={styles.iconCountry}
                      src={item.icon}
                      alt={item.code.toLowerCase()}
                      width={40}
                    />
                    <span className={styles.nameCountry}>{item.name}</span>
                  </li>
                </div>
              )
            )}
        </ul>
      </div>
      <div className={styles.content}>
        {items.map((item, index) => (
          <TileBlock key={index} country={item} setModal={setModal} />
        ))}
      </div>
    </div>
  );
};

export default SectionCountries;
