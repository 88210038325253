import React from "react";
import * as styles from "./SectionGallery.module.scss";
import SectionGalleryI from "./SectionGallery.type";
import { cn } from "../../../utils";
import { GatsbyImage } from "gatsby-plugin-image";

const SectionGallery = ({ images }: SectionGalleryI) => {
  return (
    <div className={styles.element}>
      <div className={styles.wrapper}>
        {images.map((item, index) => (
          <div
            key={index}
            className={cn([
              styles.piece,
              item.background_color ? styles[item.background_color] : "",
            ])}
          >
            <GatsbyImage
              image={item.image.gatsbyImageData}
              alt={item.image.title}
              key={index}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionGallery;
