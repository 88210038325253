import React from "react";
import SectionPreferenceI from "./SectionPreference.type";
import * as styles from "./SectionPreference.module.scss";
import { cn } from "../../../utils";
import { GatsbyImage } from "gatsby-plugin-image";
import { isGatsbyRichtext } from "../../../utils/helpers";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import ReactMarkdown from "react-markdown";

const SectionPreference = ({
  icon,
  title,
  text,
  three_columns,
  background,
  style,
  url
}: SectionPreferenceI) => {
  return (
    <div
      className={cn([
        styles.element,
        styles[style],
        three_columns ? styles["column"] : "",
        background ? styles.background : "",
      ])}
    >
      <div className={cn([style === "justworks_top_bar" ? styles.top_bar : "",three_columns ? styles["header"] : ""])}>
        {icon.gatsbyImageData == null && icon.url != null ? (
          <img src={icon.url} alt={icon.title} className={styles.icon} />
        ) : (
          <GatsbyImage className={styles.gatsbyImgWrapper} image={icon.gatsbyImageData} alt={icon.title} />
        )}
        <h4 className={styles.title}>{title}</h4>
      </div>
      <p className={styles.description}>
        {text &&
            (isGatsbyRichtext(text) ? (
              renderRichText(text)
            ) : (
              <ReactMarkdown children={text} />
            ))}
        </p>
      {url && <div className={styles.linkContainer}>
          <a href={url} className={styles.link}>
            Learn More
            </a>
      </div>}
    </div>
  );
};

export default SectionPreference;
