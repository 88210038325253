import React from "react";
import * as styles from "./SectionHubs.module.scss";
import HeaderTitle from "../../HeaderTitle/HeaderTitle";
import SectionHubsI from "./SectionHubs.type";
import { cn } from "../../../utils";
import SectionPreference from "../SectionPreference/SectionPreference";
import { GatsbyImage } from "gatsby-plugin-image";

const SectionHubs = ({ title, image, items }: SectionHubsI) => {
  return (
    <div className={styles.element}>
      <div className={cn([styles.wrapper, styles["screen"], styles["mobile"]])}>
        <GatsbyImage image={image.gatsbyImageData} alt={image.title} />
      </div>
      <HeaderTitle
        type={"section"}
        title={title.title}
        titleColor={title.title_color}
        secondTitle={title.second_title}
        secondTitleColor={title.second_title_color}
        center={false}
        description={title.description}
        classNameTitle={styles.title}
        classNameText={styles.description}
      />
      <div className={styles.content}>
        <div className={cn([styles.wrapper, styles["item"]])}>
          {items.map((item, index) => (
            <SectionPreference
              key={index}
              icon={item.image}
              title={item.title}
              text={item.description.description}
              background={false}
            />
          ))}
        </div>
        <div
          className={cn([styles.wrapper, styles["screen"], styles["desktop"]])}
        >
          <GatsbyImage image={image.gatsbyImageData} alt={image.title} />
        </div>
      </div>
    </div>
  );
};

export default SectionHubs;
