import React, { useState } from "react";
import { cn } from "../../../../utils";
import * as styles from "./QuestionBlock.module.scss";
import QuestionI from "../../../../types/QuestionI.type";

const QuestionBlock = ({ question, answer, fullWidth }: QuestionI) => {
  const [open, setOpen] = useState(false);
  return (
    <div className={cn([styles.wrapperQuestion, fullWidth ? styles.fullWidth : "", open ? styles.open : ""])}>
      <p className={styles.question} onClick={() => setOpen(!open)}>
        <span className={styles.questionText}>{question.question}</span>
        <span className={styles.fa} />
      </p>
      <hr className={styles.hr} />
      <p className={styles.answer}>{answer.answer}</p>
    </div>
  );
};

export default QuestionBlock;
