import React from "react";
import * as styles from "./SectionQuestions.module.scss";
import HeaderTitle from "../../HeaderTitle/HeaderTitle";
import QuestionBlock from "./QuestionBlock/QuestionBlock";
import SectionQuestionsI from "./SectionQuestions.type";
import { cn } from "../../../utils";

const SectionQuestions = ({ title, items, background = true, fullWidth = false }: SectionQuestionsI) => {
  return (
    <div className={cn([styles.element, background ? styles.background : ""])}>
      <HeaderTitle
        type={"section"}
        title={title.title}
        titleColor={title.title_color}
        secondTitle={title.second_title}
        secondTitleColor={title.second_title_color}
        description={title.description}
        secondLine={title.second_line}
        center={true}
        classNameTitle={styles.title}
      />
      {items.map((item, index) => (
        <QuestionBlock
          key={index}
          question={item.question}
          answer={item.answer}
          fullWidth={fullWidth}
        />
      ))}
    </div>
  );
};

export default SectionQuestions;
