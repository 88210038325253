// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var element = "SectionCompliance-module--element--38VT0";
export var title = "SectionCompliance-module--title--2_bNL";
export var middleTitle = "SectionCompliance-module--middleTitle--3YeH8";
export var description = "SectionCompliance-module--description---4J6q";