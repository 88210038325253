// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var element = "SectionPreference-module--element--3Y7Ah";
export var justworks = "SectionPreference-module--justworks--dc-Dw";
export var header = "SectionPreference-module--header--3Lftu";
export var justworks_top_bar = "SectionPreference-module--justworks_top_bar--3H03N";
export var top_bar = "SectionPreference-module--top_bar--2sk-S";
export var title = "SectionPreference-module--title--1mSvk";
export var description = "SectionPreference-module--description--1nU_o";
export var icon = "SectionPreference-module--icon--2LlbI";
export var column = "SectionPreference-module--column--1HROI";
export var gatsbyImgWrapper = "SectionPreference-module--gatsbyImgWrapper--3R4y_";
export var background = "SectionPreference-module--background--2QHtJ";
export var linkContainer = "SectionPreference-module--linkContainer--2eFa3";