import React from "react";
import Slider from "react-slick";
import * as styles from "./SectionSlider.module.scss";
import { cn } from "../../../utils";
import NextArrow from "./NextArrow/NextArrow";
import PrevArrow from "./PrevArrow/PrevArrow";
import SectionSliderI from "./SectionSlider.type";
import { GatsbyImage } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";

const SectionSlider = ({ items }: SectionSliderI) => {
  return (
    <div className={styles.element}>
      <Slider
        dots={true}
        className={styles.slide}
        slidesToShow={1}
        infinite={true}
        arrows={true}
        variableWidth={true}
        centerMode={true}
        responsive={[
          {
            breakpoint: 576,
            settings: {
              variableWidth: false,
              centerMode: false,
            },
          },
        ]}
        dotsClass={cn(["slick-dots", styles.dots])}
        nextArrow={<NextArrow />}
        prevArrow={<PrevArrow />}
      >
        {items.map((item, index) => (
          <div key={index} className={styles.slideBody}>
            <div className={styles.slideTop}>
              <GatsbyImage
                image={item.avatar.gatsbyImageData}
                alt={"avatar"}
                className={styles.img}
              />
              <div>
                <p className={styles.name}>{item.name_surname}</p>
                <p className={styles.position}>
                  {item.position}
                  <span className={styles.company}> {item.company}</span>
                </p>
              </div>
            </div>
            <div className={styles.text}>
              {item.description && renderRichText(item.description)}
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default SectionSlider;
