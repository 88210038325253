// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var title_wrapper = "SectionTitle-module--title_wrapper--1HWIb";
export var yellow = "SectionTitle-module--yellow--1-GVz";
export var purple = "SectionTitle-module--purple--Rvxzk";
export var white = "SectionTitle-module--white--3k-ec";
export var grid_item = "SectionTitle-module--grid_item--2XMtH";
export var inner_content = "SectionTitle-module--inner_content--2U__T";
export var element = "SectionTitle-module--element--2apwd";
export var vertical_image_top = "SectionTitle-module--vertical_image_top--3ptoK";
export var vertical_image_bottom = "SectionTitle-module--vertical_image_bottom--1P5IH";
export var gatsbyImage = "SectionTitle-module--gatsbyImage--2MUlF";
export var none = "SectionTitle-module--none--q4xvL";
export var small = "SectionTitle-module--small--3zEsq";
export var medium = "SectionTitle-module--medium--2f6Z-";
export var large = "SectionTitle-module--large--2Jli7";
export var horizontal_image_left = "SectionTitle-module--horizontal_image_left--1LGAB";
export var title_container = "SectionTitle-module--title_container--jxX7G";
export var horizontal_image_right = "SectionTitle-module--horizontal_image_right--30gYR";
export var via = "SectionTitle-module--via--2knr6";
export var justworks = "SectionTitle-module--justworks--3tdB1";
export var left = "SectionTitle-module--left--2vt1o";
export var center = "SectionTitle-module--center--2Upfj";
export var right = "SectionTitle-module--right--1kQQD";