// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var displayNone = "SectionSeoCopy-module--displayNone--DVE_w";
export var seo_copy = "SectionSeoCopy-module--seo_copy--2U0_7";
export var grid_item = "SectionSeoCopy-module--grid_item--g-QPh";
export var inner_content = "SectionSeoCopy-module--inner_content--cAA9_";
export var seo_copy_title = "SectionSeoCopy-module--seo_copy_title--3Reqb";
export var all_countries_link = "SectionSeoCopy-module--all_countries_link--1kVSK";
export var country_all_text = "SectionSeoCopy-module--country_all_text--1emwZ";
export var image_text = "SectionSeoCopy-module--image_text--11A5Q";