import React from "react";
import * as styles from "./SectionList.module.scss";
import Button from "../../Button/Button";
import HeaderTitle from "../../HeaderTitle/HeaderTitle";
import SectionListI from "./SectionList.type";
import { cn } from "../../../utils";
import BlockList from "../../BlockList/BlockList";
import { GatsbyImage } from "gatsby-plugin-image";

const SectionList = ({
  title,
  items,
  image,
  bigImage,
  button,
  image_right,
  padding,
  setModal,
}: SectionListI) => {
  return (
    <div className={styles.listElement}>
      <div
        className={cn([
          styles.img,
          image_right ? styles.right : "",
          padding ? styles.padding : "",
        ])}
      >
        <GatsbyImage
          image={image.gatsbyImageData}
          alt={image.title}
          className={bigImage ? styles.imgNormal : ""}
        />
        {bigImage ? (
          <GatsbyImage
            image={bigImage.gatsbyImageData}
            alt={bigImage.title}
            className={styles.imgBig}
          />
        ) : null}
      </div>
      <div className={cn([styles.content, image_right ? styles.right : ""])}>
        <HeaderTitle
          type={"section"}
          title={title.title}
          titleColor={title.title_color}
          secondTitle={title.second_title}
          secondTitleColor={title.second_title_color}
          description={title.description}
          center={false}
          secondLine={title.second_line}
          classNameText={styles.description}
        />
        {items && items.length != 0 ? <BlockList items={items} /> : ""}
        {button && (
          <Button
            text={button.button_text}
            color={"dark"}
            size={"medium"}
            className={styles.button}
            contactButton={true}
          />
        )}
      </div>
    </div>
  );
};

export default SectionList;
