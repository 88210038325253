
import TitleTagI from "./TitleTagI.type";
import React from "react";

const TitleTag = ({ tag, text, titleTextSecondLine }: TitleTagI) => {
    switch (tag) {
        case "h1":
            return <h1>{text}{titleTextSecondLine ? <br/> : ""}{titleTextSecondLine ? titleTextSecondLine : ""}</h1>;
            break;
        case "h2":
            return <h2>{text}{titleTextSecondLine ? <br/> : ""}{titleTextSecondLine ? titleTextSecondLine : ""}</h2>;
            break;
        case "h3":
            return <h3>{text}{titleTextSecondLine ? <br/> : ""}{titleTextSecondLine ? titleTextSecondLine : ""}</h3>;
            break;
        case "h4":
            return <h4>{text}{titleTextSecondLine ? <br/> : ""}{titleTextSecondLine ? titleTextSecondLine : ""}</h4>;
            break;
        case "h5":
            return <h5>{text}{titleTextSecondLine ? <br/> : ""}{titleTextSecondLine ? titleTextSecondLine : ""}</h5>;
            break;
        case "h6":
            return <h6>{text}{titleTextSecondLine ? <br/> : ""}{titleTextSecondLine ? titleTextSecondLine : ""}</h6>;
            break;
        default:
            return <span>{text}{titleTextSecondLine ? <br/> : ""}{titleTextSecondLine ? titleTextSecondLine : ""}</span>;
            break;
    }
}

export default TitleTag;
