import React from "react";
import SectionRecruitI from "./SectionRecruit.type";
import * as styles from "./SectionRecruit.module.scss";
import { cn } from "../../../utils";
import SectionPreference from "../SectionPreference/SectionPreference";
import HeaderTitle from "../../HeaderTitle/HeaderTitle";

const SectionRecruit = ({
  items,
  title,
  className,
  threeColumns,
  background,
  style = "recruit",
}: SectionRecruitI) => {
  return (
    <div
      className={cn([
        styles.element,
        styles[style],
        className ? className : "",
        threeColumns ? styles["columns"] : "",
        background ? styles.background : "",
      ])}
    >
      {title && (
        <div className={styles.headerTitle}>
          <HeaderTitle
            type={"section"}
            title={title.title}
            titleColor={title.title_color}
            secondTitle={title.second_title}
            secondTitleColor={title.second_title_color}
            description={title.description}
            center={true}
            secondLine={title.second_line}
            classNameTitle={styles.title}
            classNameText={styles.description}
          />
        </div>
      )}
      <div className={cn([styles.preference])}>
        {items.map((item, index) => (
          <SectionPreference
            key={index}
            icon={item.image}
            title={item.title}
            text={item.description.description}
            three_columns={threeColumns}
            background={background}
            style={style}
            url={item.url}
          />
        ))}
      </div>
    </div>
  );
};

export default SectionRecruit;
