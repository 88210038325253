// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var element = "SectionTwoBlock-module--element--3DH-a";
export var block = "SectionTwoBlock-module--block--2HCRn";
export var description = "SectionTwoBlock-module--description--3ig1J";
export var background = "SectionTwoBlock-module--background--2Y13A";
export var title = "SectionTwoBlock-module--title--IjuPW";