// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var element = "SectionGallery-module--element--ew8N6";
export var wrapper = "SectionGallery-module--wrapper--2jDKj";
export var piece = "SectionGallery-module--piece--18Nba";
export var dark = "SectionGallery-module--dark--3h2r8";
export var peach = "SectionGallery-module--peach--2SixR";
export var white = "SectionGallery-module--white--2x10c";
export var green = "SectionGallery-module--green--3f7V-";
export var blue = "SectionGallery-module--blue--2l1Da";
export var gold = "SectionGallery-module--gold--svYHx";