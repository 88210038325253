// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var element = "SectionInvestors-module--element--3EvM8";
export var title = "SectionInvestors-module--title--2jAA-";
export var description = "SectionInvestors-module--description--18EOO";
export var investors = "SectionInvestors-module--investors--2n3tS";
export var wrapperImg = "SectionInvestors-module--wrapperImg--1q-9-";
export var img = "SectionInvestors-module--img--Mb0Pv";