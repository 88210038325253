import React from "react";
import * as styles from "./SectionPolicy.module.scss";
import SectionPolicyI from "./SectionPolicy.type";
import { renderRichText } from "gatsby-source-contentful/rich-text";

const SectionPolicy = ({ paragraphs }: SectionPolicyI) => {
  return (
    <div className={styles.element}>
      <div className={styles.content}>
        {paragraphs.map((paragraph, index) => (
          <div key={index} className={styles.block}>
            <h4 className={styles.subtitle}>{paragraph.subtitle}</h4>
            <div
              className={paragraph.list_type ? styles[paragraph.list_type] : ""}
            >
              {renderRichText(paragraph.text)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionPolicy;
