import React from "react";
import SectionRecruit from "../Sections/SectionRecruit/SectionRecruit";
import SectionList from "../Sections/SectionList/SectionList";
import SectionCompanies from "../Sections/SectionCompanies/SectionCompanies";
import SectionExperience from "../Sections/SectionExperience/SectionExperience";
import SectionBuy from "../Sections/SectionBuy/SectionBuy";
import SectionCountriesBanner from "../Sections/SectionCountriesBanner/SectionCountriesBanner";
import SectionQuestions from "../Sections/SectionQuestions/SectionQuestions";
import SectionQuote from "../Sections/SectionQuote/SectionQuote";
import SectionInvestors from "../Sections/SectionInvestors/SectionInvestors";
import SectionPositions from "../Sections/SectionPositions/SectionPositions";
import SectionHubs from "../Sections/SectionHubs/SectionHubs";
import SectionLocals from "../Sections/SectionLocals/SectionLocals";
import SectionCompliance from "../Sections/SectionCompliance/SectionCompliance";
import SectionCTA from "../Sections/SectionCTA/SectionCTA";
import SectionTwoBlock from "../Sections/SectionTwoBlock/SectionTwoBlock";
import SectionGallery from "../Sections/SectionGallery/SectionGallery";
import SectionPolicy from "../Sections/SectionPolicy/SectionPolicy";
import SectionCountries from "../Sections/SectionCountries/SectionCountries";
import SectionSeoCopy from "../Sections/SectionSeoCopy/SectionSeoCopy";
import BlogGrid from "../BlogPost/BlogGrid/BlogGrid";
import SectionI from "./Section.type";
import SectionTitle from "../Sections/SectionTitle/SectionTitle";
import SectionForm from "../Sections/SectionForm/SectionForm";

const Section = ({ sections, setModal, images, trendingBlogs }: SectionI) => {
  const result: any[] = [];
  sections.map((section, index) => {
    switch (section.__typename) {
      case "ContentfulForm":
      result.push(<SectionForm hubspotFormId={section.hubspotFormId} internalLinkId={section.internalLinkId} title={section.title}/>)  
      break;
      case "ContentfulSeoCopy":
        result.push(<SectionSeoCopy key={index} copy={section.copy} copyTitle={section.copyTitle} />);
        break;
      case "ContentfulBlogPostBanner":
        if (section.enable) {
          result.push(<BlogGrid blogs={trendingBlogs.slice(0, 3)} />);
        }
        break;
      case "ContentfulTitle":
        result.push(
          <SectionTitle
            contentAlignment={section.contentAlignment}
            key={index}
            titleText={section.titleText}
            titleTextSecondLine={section.titleTextSecondLine}
            titleTag={section.titleTag}
            alignment={section.alignment}
            rich_text={section.rich_text}
            button={section.button}
            image={section.image}
            spacing={section.spacing}
            bg_color={section.bg_color}
          />
        );
        break;
      case "ContentfulCountriesBanner":
        result.push(
          <SectionCountriesBanner
            items={section.items}
            setModal={setModal}
            images={section.images}
          />
        );
        break;
      case "ContentfulRecruit":
        result.push(
          <SectionRecruit
            key={index}
            items={section.items}
            title={section.title}
            threeColumns={section.three_columns}
            background={section.background}
            style={section.style}
          />
        );
        break;
      case "ContentfulList":
        result.push(
          <SectionList
            key={index}
            title={section.title}
            items={section.items}
            image={section.image}
            bigImage={section.big_image}
            button={section.button}
            image_right={section.image_right}
            padding={section.padding}
            setModal={setModal}
          />
        );
        break;
      case "ContentfulSlider":
        result.push(
          <SectionCompanies
            key={index}
            title={section.title}
            items={section.items}
            companies={section.companies}
          />
        );
        break;
      case "ContentfulExperience":
        result.push(
          <SectionExperience
            key={index}
            title={section.title}
            items={section.items}
          />
        );
        break;
      case "ContentfulBuy":
        result.push(
          <SectionBuy
            key={index}
            title={section.title}
            items={section.items}
            background={section.background}
            setModal={setModal}
          />
        );
        break;
      case "ContentfulQuote":
        result.push(
          <SectionQuote
            key={index}
            quote={section.quote}
            image={section.image}
            mission={section.mission}
          />
        );
        break;
      case "ContentfulInvestors":
        result.push(
          <SectionInvestors
            key={index}
            title={section.title}
            investors={section.investors}
          />
        );
        break;
      case "ContentfulPositions":
        result.push(
          <SectionPositions
            key={index}
            title={section.title}
            button={section.button}
          />
        );
        break;
      case "ContentfulHubs":
        result.push(
          <SectionHubs
            key={index}
            title={section.title}
            image={section.image}
            items={section.items}
          />
        );
        break;
      case "ContentfulLocals":
        result.push(
          <SectionLocals
            key={index}
            title={section.title}
            image={section.image}
            info={section.info}
          />
        );
        break;
      case "ContentfulGallery":
        result.push(<SectionGallery key={index} images={section.images} />);
        break;
      case "ContentfulQuestions":
        result.push(
          <SectionQuestions
            key={index}
            title={section.title}
            items={section.items}
            background={section.background}
            fullWidth={section.fullWidth}
          />
        );
        break;
      case "ContentfulCompliance":
        result.push(
          <SectionCompliance
            key={index}
            complianceTitle={section.compliance_title}
            description={section.description}
          />
        );
        break;
      case "ContentfulCta":
        result.push(
          <SectionCTA
            key={index}
            bg_color={section.bg_color}
            title={section.title}
            button={section.button}
            smallTitle={section.small_title}
            borderRadius={section.border_radius}
            setModal={setModal}
          />
        );
        break;
      case "ContentfulPolicy":
        result.push(
          <SectionPolicy key={index} paragraphs={section.paragraphs} />
        );
        break;
      case "ContentfulTwoBlock":
        result.push(
          <SectionTwoBlock
            key={index}
            title_block_1={section.title_block_1}
            title_block_2={section.title_block_2}
            items_block_1={section.item_block_1}
            items_block_2={section.item_block_2}
            background_block_1={section.background_block_1}
            background_block_2={section.background_block_2}
          />
        );
        break;
      case "ContentfulCountries":
        result.push(
          <SectionCountries
            key={index}
            items={section.items}
            setModal={setModal}
            images={images}
          />
        );
        break;
      default:
        result.push("");
        break;
    }
  });
  return <>{result}</>;
};

export default Section;
