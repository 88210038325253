// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var element = "ExperienceItem-module--element--2mu74";
export var image = "ExperienceItem-module--image--3cE3t";
export var title = "ExperienceItem-module--title--3o_bl";
export var description = "ExperienceItem-module--description--1pplZ";
export var bigImage = "ExperienceItem-module--bigImage--qLko2";