// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var element = "JobBoard-module--element--2fKeO";
export var searchBlock = "JobBoard-module--searchBlock--3BI4h";
export var filter = "JobBoard-module--filter--2wVut";
export var search = "JobBoard-module--search--2hLWl";
export var panel = "JobBoard-module--panel--GuL5p";
export var filterBlock = "JobBoard-module--filterBlock--2OXRc";
export var open = "JobBoard-module--open--muumY";
export var icon = "JobBoard-module--icon--2IoYG";
export var content = "JobBoard-module--content--hqqQ7";