import BlogGridI from "./BlogGridI.type";
import * as styles from "./BlogGrid.module.scss";
import BlogPostCard from "../BlogPostCard/BlogPostCard";
import BlogPostExcerptI from "../../../types/BlogPostExcerptI.type";
import BlogPostExcerptNodeI from "../../../types/BlogPostExcerptNodeI.type";

const BlogGrid = ({ blogs }: BlogGridI) => {
  return (
    <div className={styles.BlogGridContainer}>
      <div className={styles.relatedArticlesContainer}>
        <div className={styles.relatedArticles}>
          {blogs.map((blog: BlogPostExcerptI, index) => {
            const article: BlogPostExcerptNodeI = {
              node: {
                id: blog.id || "",
                slug: blog.slug,
                blogTitle: blog.blogTitle,
                excerpt: blog.excerpt,
                heroImage: blog.heroImage,
                publishedDate: blog.publishedDate,
                blogTag: blog.blogTag,
              },
            };
            return (
              <BlogPostCard
                key={`blogPostCard_${index}`}
                blog={article}
              ></BlogPostCard>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BlogGrid;
