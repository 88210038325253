// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var element = "SectionHubs-module--element--2fVNI";
export var content = "SectionHubs-module--content--3rGHi";
export var wrapper = "SectionHubs-module--wrapper--2Jko5";
export var title = "SectionHubs-module--title--1CrlG";
export var description = "SectionHubs-module--description--3YxHz";
export var screen = "SectionHubs-module--screen--SN-Ek";
export var item = "SectionHubs-module--item--3hDLN";
export var mobile = "SectionHubs-module--mobile--16MIr";
export var desktop = "SectionHubs-module--desktop--7U9vt";