import React from "react";
import * as styles from "./SectionLocals.module.scss";
import HeaderTitle from "../../HeaderTitle/HeaderTitle";
import SectionLocalsI from "./SectionLocals.type";

const SectionLocals = ({ title, image, info }: SectionLocalsI) => {
  return (
    <div className={styles.element}>
      <div className={styles.content}>
        <div className={styles.text}>
          <HeaderTitle
            type={"section"}
            title={title.title}
            titleColor={title.title_color}
            secondTitle={title.second_title}
            secondTitleColor={title.second_title_color}
            center={false}
            text={title.description}
            secondLine={title.second_line}
            classNameText={styles.description}
          />
          <p className={styles.subtitle}>{info.subtitle}</p>
          <p className={styles.details}>{info.details}</p>
        </div>
        <div className={styles["screen"]}>
          <img src={image.url} alt={image.alternativeText} />
        </div>
      </div>
    </div>
  );
};

export default SectionLocals;
