// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var element = "SectionBuy-module--element--3fwWi";
export var background = "SectionBuy-module--background--2V4fc";
export var tiles = "SectionBuy-module--tiles--3Mtds";