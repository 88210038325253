import React from "react";
import * as styles from "./SectionInvestors.module.scss";
import HeaderTitle from "../../HeaderTitle/HeaderTitle";
import SectionInvestorsI from "./SectionInvestors.type";
import { GatsbyImage } from "gatsby-plugin-image";

const SectionInvestors = ({ title, investors }: SectionInvestorsI) => {
  return (
    <div className={styles.element}>
      <HeaderTitle
        type={"section"}
        title={title.title}
        secondTitle={title.second_title}
        titleColor={title.title_color}
        secondTitleColor={title.second_title_color}
        center={true}
        description={title.description}
        secondLine={title.second_line}
        classNameTitle={styles.title}
        classNameText={styles.description}
      />
      <div className={styles.investors}>
        {investors.map((investor, index) => (
          <div key={index} className={styles.wrapperImg}>
            <a href={investor.slug ? investor.slug : "#"}>
              <GatsbyImage
                image={investor.icon.gatsbyImageData}
                alt={investor.icon.title}
                className={styles.img}
                key={index}
              />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionInvestors;
