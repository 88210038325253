import React from "react";
import * as styles from "./SectionExperience.module.scss";
import HeaderTitle from "../../HeaderTitle/HeaderTitle";
import ExperienceItem from "./ExperienceItem/ExperienceItem";
import SectionExperienceI from "./SectionExperience.type";

const SectionExperience = ({ title, items }: SectionExperienceI) => {
  return (
    <div className={styles.element}>
      <HeaderTitle
        type={"section"}
        title={title.title}
        titleColor={title.title_color}
        secondTitle={title.second_title}
        secondTitleColor={title.second_title_color}
        description={title.description}
        center={true}
        secondLine={title.second_line}
        classNameText={styles.description}
      />
      <div className={styles.experience}>
        {items.map((item, index) => (
          <ExperienceItem
            key={index}
            title={item.title}
            description={item.description}
            image={item.image}
            bigImage={item.bigImage}
          />
        ))}
      </div>
    </div>
  );
};

export default SectionExperience;
