// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var element = "SectionQuestions-module--element--77QIQ";
export var background = "SectionQuestions-module--background--3fNpw";
export var title = "SectionQuestions-module--title--wkY8x";