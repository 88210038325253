import React from "react";
import SectionTitleSimpleI from "./SectionTitleI.type";
import * as styles from "./SectionTitle.module.scss";
import ReactMarkdown from "react-markdown";
import { isGatsbyRichtext } from "../../../utils/helpers";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import TitleTag from "../../TitleTag/TitleTag";
import Button from "../../Button/Button";
import { cn } from "../../../utils";
import { GatsbyImage } from "gatsby-plugin-image";

const renderOptions = {
  renderText: (text: string): JSX.Element => {
    const textSegments = text.split('\n');
    const lastIndex = textSegments.length - 1;

    return (
      <>
        {textSegments.map((segment, index) => (
          <React.Fragment key={index}>
            {index !== lastIndex ? <br /> : segment}
            {}
          </React.Fragment>
        ))}
      </>
    );
  },
};


const SectionTitle = ({ titleText, bg_color = "white", titleTextSecondLine, titleTag, alignment, rich_text, button, image, contentAlignment="vertical_image_bottom", spacing="small" }: SectionTitleSimpleI) => {
  return (
    <div className={cn([styles.title_wrapper,styles[bg_color]])}>
      <div className={cn([styles.title_container,styles[alignment],styles[spacing]])}>
        <div className={cn([styles[contentAlignment],styles.grid_item])}>
          {image && <div className={styles.gatsbyImage}>
            <GatsbyImage image={image.gatsbyImageData} alt={image.title}/>
            </div>}
          <div className={styles.inner_content}>
            <TitleTag text={titleText} titleTextSecondLine={titleTextSecondLine} tag={titleTag} />
            {rich_text &&
              (isGatsbyRichtext(rich_text) ? (
                renderRichText(rich_text,renderOptions)
              ) : (
                <ReactMarkdown children={rich_text} />
              ))}
            {button && (<Button {...button} alignment={alignment} />)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionTitle;
