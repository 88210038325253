import React from "react";
import ExperienceItemI from "./ExperienceItem.type";
import * as styles from "./ExperienceItem.module.scss";
import { GatsbyImage } from "gatsby-plugin-image";

const ExperienceItem = ({
  title,
  description,
  image,
  bigImage,
}: ExperienceItemI) => {
  return (
    <div className={styles.element}>
      {bigImage && (
        <GatsbyImage
          image={bigImage.gatsbyImageData}
          alt={bigImage.title}
          className={styles.bigImage}
        />
      )}
      <GatsbyImage
        image={image.gatsbyImageData}
        alt={image.title}
        className={styles.image}
      />
      <h4 className={styles.title}>{title}</h4>
      <p className={styles.description}>{description.description}</p>
    </div>
  );
};

export default ExperienceItem;
