// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var element = "SectionPositions-module--element--2Xu8K";
export var positions = "SectionPositions-module--positions--2HLuY";
export var buttons = "SectionPositions-module--buttons--yQRDy";