// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var element = "SectionQuote-module--element--3iWAQ";
export var mission = "SectionQuote-module--mission--3lw_k";
export var title = "SectionQuote-module--title--1FaZM";
export var wrapperImg = "SectionQuote-module--wrapperImg--Xo6EK";
export var content = "SectionQuote-module--content--B5PGb";
export var text = "SectionQuote-module--text--3VpoT";
export var quote = "SectionQuote-module--quote--1lPsd";
export var name = "SectionQuote-module--name--2ldCz";
export var role = "SectionQuote-module--role--3iz7R";
export var sign = "SectionQuote-module--sign--27cp7";
export var top = "SectionQuote-module--top--2tWdT";
export var bottom = "SectionQuote-module--bottom--1ReRz";
export var img = "SectionQuote-module--img--NlBEG";