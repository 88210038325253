import React from "react";
import BlockListI from "./BlockList.type";
import * as styles from "./BlockList.module.scss";
import { cn } from "../../utils";

const BlockList = ({ items, full }: BlockListI) => {
  return (
    <div className={styles.element}>
      {items.map((item, index) => (
        <span
          key={index}
          className={cn([styles.text, full ? styles.full : ""])}
        >
          {item.item}
        </span>
      ))}
    </div>
  );
};

export default BlockList;
