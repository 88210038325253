// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var listElement = "SectionList-module--listElement--3a-LU";
export var button = "SectionList-module--button--1iicD";
export var img = "SectionList-module--img--1eTe0";
export var imgNormal = "SectionList-module--imgNormal--ocnzy";
export var padding = "SectionList-module--padding--26zQ6";
export var description = "SectionList-module--description--15p0F";
export var content = "SectionList-module--content--1t25l";
export var imgBig = "SectionList-module--imgBig--B7t_p";
export var right = "SectionList-module--right--nhlpf";