// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var element = "SectionExperience-module--element--38x26";
export var description = "SectionExperience-module--description--3KIWQ";
export var experience = "SectionExperience-module--experience--2Zde-";