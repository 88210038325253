import { isGatsbyImage } from "../../utils/helpers";
import * as styles from "./TileBlock.module.scss";
import TileBlockI from "./TileBlock.type";
import { GatsbyImage } from "gatsby-plugin-image";

const TileBlock = ({ country, item, setModal }: TileBlockI) => {
  return (
    <>
      {country != undefined ? (
        <div
          className={styles.country}
          id={country.text.toLowerCase()}
          onClick={country.slug ? () => false : setModal}
        >
          <a href={country.slug ? country.slug + "/" : "#"}>
            <div className={styles.top}>
              <div>
                <p>
                  <span className={styles.countryName}>{country.text}</span>
                </p>
              </div>
              <div>
                {isGatsbyImage(country.icon) ? (
                  <GatsbyImage
                    image={country.icon.gatsbyImageData}
                    alt={country.icon.title}
                    class={styles.img}
                  />
                ) : (
                  <img
                    className={styles.img}
                    src={country.icon.url}
                    alt={country.icon.alternativeText}
                  />
                )}
              </div>
            </div>
          </a>
        </div>
      ) : item != undefined ? (
        <div className={styles.item}>
          <p className={styles.industry}>{item.industry}</p>
          <a href={item.hostedUrl} target={"_blank"}>
            <p className={styles.type}>{item.type}</p>
          </a>
          <p className={styles.region}>{item.region}</p>
        </div>
      ) : (
        <h1 className={styles.error}>Item not found!</h1>
      )}
    </>
  );
};

export default TileBlock;
