import React from "react";
import * as styles from "./SectionCountriesBanner.module.scss";
import TileBlock from "../../TileBlock/TileBlock";
import SectionCountriesI from "./SectionCountriesBanner.type";

const SectionCountriesBanner = ({ items, setModal }: SectionCountriesI) => {
  return (
    <div className={styles.element}>
      <div className={styles.content}>
        {items.map((item, index) => (
          <TileBlock key={index} country={item} setModal={setModal} />
        ))}
      </div>
    </div>
  );
};

export default SectionCountriesBanner;
