// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var element = "BlockList-module--element--2B5Jy";
export var text = "BlockList-module--text--1LWjC";
export var full = "BlockList-module--full--30ydP";