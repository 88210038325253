// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var item = "TileBlock-module--item--uIDJK";
export var country = "TileBlock-module--country--s4dkt";
export var img = "TileBlock-module--img--1pZAm";
export var industry = "TileBlock-module--industry--oy9-8";
export var type = "TileBlock-module--type--1COHd";
export var region = "TileBlock-module--region--2JrxW";
export var top = "TileBlock-module--top---JIc0";
export var countryName = "TileBlock-module--countryName--1lpU1";
export var link = "TileBlock-module--link--3fogW";
export var error = "TileBlock-module--error--2sPKu";