import React, { useEffect, useMemo, useState } from "react";
import * as styles from "./JobBoard.module.scss";
import TileBlock from "../TileBlock/TileBlock";
import Pagination from "./Pagination/Pagination";
import _ from "lodash";
import { PAGE_SIZE } from "../../utils/constants";
import { cn } from "../../utils";
import Select from "react-select";
import JobItemI from "../../types/JobItemI.type";

const JobBoard = () => {
  const [items, setItems] = useState<JobItemI[]>([]);
  const [jobs, setJobs] = useState<JobItemI[]>([]);
  const [openFilters, setOpenFilters] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filterRegion, setFilterRegion] = useState({
    label: "Region",
    value: "region",
  });
  const [availableFilterRegions, setAvailableFilterRegions] = useState([
    filterRegion,
  ]);
  const [filterJobIndustry, setFilterJobIndustry] = useState({
    label: "Function",
    value: "function",
  });
  const [availableFilterJobIndustry, setAvailableFilterJobIndustry] = useState([
    filterJobIndustry,
  ]);
  const [activeFilters, setActiveFilters] = useState({
    region: filterRegion.value,
    regionLabel: filterRegion.label,
    industry: filterJobIndustry.value,
    industryLabel: filterJobIndustry.label,
    search: searchValue,
  });
  const [currentPage, setCurrentPage] = useState<any>(1);

  useEffect(() => {
    (async () => {
      try {
        const items: JobItemI[] = [];
        const response = await fetch(
          `https://api.lever.co/v0/postings/via?mode=json`
        );
        const json = await response.json();
        json.map((item: any) => {
          const job: JobItemI = {
            hostedUrl: item.hostedUrl,
            industry: item.categories.team,
            type: item.text,
            region: item.categories.location,
          };
          items.push(job);
        });
        setJobs(items);
        setItems(items);
      } catch (err) {}
    })();
  }, []);

  useEffect(() => {
    const jobIndustry = [{ label: "Function", value: "function" }];
    const regions = [{ label: "Region", value: "region" }];
    items.forEach(job => {
      regions.push({
        label: job.region,
        value: job.region.toLowerCase(),
      });

      jobIndustry.push({
        label: job.industry,
        value: job.industry.toLowerCase(),
      });
    });
    setAvailableFilterJobIndustry(_.uniqBy(jobIndustry, "value"));
    setAvailableFilterRegions(_.uniqBy(regions, "value"));
  }, [items]);

  useEffect(() => {
    setActiveFilters({
      region: filterRegion.value,
      regionLabel: filterRegion.label,
      industry: filterJobIndustry.value,
      industryLabel: filterJobIndustry.label,
      search: searchValue,
    });
  }, [filterRegion, filterJobIndustry, searchValue]);

  useEffect(() => {
    const filteredJobs = items.filter(job => {
      if (
        activeFilters.region !== "region" &&
        activeFilters.region !== job.region.toLowerCase()
      ) {
        return false;
      }
      if (
        activeFilters.industry !== "function" &&
        activeFilters.industry !== job.industry.toLowerCase()
      ) {
        return false;
      }
      if (
        activeFilters.search !== "" &&
        !job.type.toLowerCase().includes(activeFilters.search.toLowerCase())
      ) {
        return false;
      }
      return true;
    });
    setJobs(filteredJobs);
    setCurrentPage(1);
  }, [activeFilters, items]);

  const currentItemsData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PAGE_SIZE;
    const lastPageIndex = firstPageIndex + PAGE_SIZE;
    return jobs.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, jobs]);

  return (
    <div className={styles.element}>
      <div className={styles.panel}>
        <div className={styles.searchBlock}>
          <input
            className={styles.search}
            type={"search"}
            placeholder={"Search role"}
            onChange={e => setSearchValue(e.target.value)}
          />
          <span
            className={styles.icon}
            onClick={() => setOpenFilters(!openFilters)}
          />
        </div>
        <div
          className={cn([styles.filterBlock, openFilters ? styles.open : ""])}
        >
          <Select
            className={styles.filter}
            isSearchable={false}
            styles={{
              control: base => ({
                ...base,
                border: 0,
                boxShadow: "none",
              }),
            }}
            options={availableFilterRegions}
            value={{
              value: activeFilters.region,
              label: activeFilters.regionLabel,
            }}
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator: () => null,
            }}
            onChange={(e: any) => {
              setFilterRegion({
                label: e.label,
                value: e.value,
              });
            }}
          />
          <Select
            className={styles.filter}
            isSearchable={false}
            styles={{
              control: base => ({
                ...base,
                border: 0,
                boxShadow: "none",
              }),
            }}
            options={availableFilterJobIndustry}
            value={{
              value: activeFilters.industry,
              label: activeFilters.industryLabel,
            }}
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator: () => null,
            }}
            onChange={(e: any) => {
              setFilterJobIndustry({
                label: e.label,
                value: e.value,
              });
            }}
          />
        </div>
      </div>
      <div className={styles.content}>
        {currentItemsData.map((item, index) => (
          <TileBlock key={index} item={item} />
        ))}
      </div>
      {jobs.length > PAGE_SIZE ? (
        <Pagination
          currentPage={currentPage}
          totalCount={jobs.length}
          pageSize={PAGE_SIZE}
          onPageChange={page => setCurrentPage(page)}
        />
      ) : null}
    </div>
  );
};

export default JobBoard;
