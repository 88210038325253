// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var blogGridContainer = "BlogGrid-module--blogGridContainer--8i3QW";
export var relatedArticles = "BlogGrid-module--relatedArticles--1i25E";
export var relatedArticlesContainer = "BlogGrid-module--relatedArticlesContainer--2c9Qq";