// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var element = "SectionSlider-module--element--1QlE-";
export var dots = "SectionSlider-module--dots--2JMLo";
export var slide = "SectionSlider-module--slide--yh-En";
export var slideBody = "SectionSlider-module--slideBody--wHgti";
export var slideTop = "SectionSlider-module--slideTop--2JDDM";
export var img = "SectionSlider-module--img--2tIyH";
export var name = "SectionSlider-module--name--2IhDV";
export var position = "SectionSlider-module--position--38NlC";
export var company = "SectionSlider-module--company--3z4Rl";
export var text = "SectionSlider-module--text--22ajT";