// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var pagination = "Pagination-module--pagination--3u4bo";
export var link = "Pagination-module--link--xus9j";
export var dots = "Pagination-module--dots--2dwRZ";
export var current = "Pagination-module--current--2NiLa";
export var btn = "Pagination-module--btn--19xAZ";
export var btnPrev = "Pagination-module--btnPrev--3132R";
export var disabled = "Pagination-module--disabled--3q89U";
export var btnNext = "Pagination-module--btnNext--35xXD";