import React, { useEffect, useState } from "react";
import "../assets/styles/base.scss";
import Layout from "../components/Layout/Layout";
import CookiesBanner from "../components/CookiesBanner/CookiesBanner";
import { useCookie } from "../utils/getCookie";
import JobBoard from "../components/JobBoard/JobBoard";
import RegisterForm from "../components/RegisterForm/RegisterForm";
import DynamicSection from "../components/Section/Section";

const Page = ({ pageContext }: any) => {
  const {
    sections,
    title,
    titleImage,
    justworksHeader,
    headerBackgroundImage,
    headerBackgroundColor,
    header_background,
    button,
    page_background,
    seo,
    banner,
    hideFooter,
    url = typeof window !== "undefined" ? window.location.pathname : "",
  } = pageContext.data;
  const {
    footer_menu,
    header_menu,
    footer,
    logo,
    cookieBarDescription,
    cookiePolicyLink,
    cookieBarTitle,
  } = pageContext.globalSettings;
  const [showCookiesConsent, setShowCookiesConsent] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const cookie = useCookie("gatsby-gdpr-tracking");
    if (!cookie) {
      setShowCookiesConsent(true);
    }
  }, []);
  return (
    <Layout
      title={title}
      button={button}
      footer_menu={footer_menu}
      header_menu={header_menu}
      footer={footer}
      logo={logo}
      background={header_background}
      page_background={page_background}
      dataSEO={seo}
      url={url}
      setModal={setShowModal}
      countryPage={false}
      banner={banner}
      titleImage={titleImage}
      headerBackgroundImage={headerBackgroundImage}
      headerBackgroundColor={headerBackgroundColor}
      hideFooter={hideFooter}
      justworksHeader={justworksHeader}
    >
      {url.indexOf("thank-you") ? null : null}
      {url === "/job-board/" ? <JobBoard /> : null}
      <DynamicSection
        sections={sections}
        setModal={() => setShowModal(true)}
        images={pageContext.images}
        trendingBlogs={pageContext.trendingBlogs}
      />
      {showCookiesConsent && (
        <CookiesBanner
          dismiss={() => setShowCookiesConsent(false)}
          cookieBarDescription={cookieBarDescription}
          cookiePolicyLink={cookiePolicyLink}
          cookieBarTitle={cookieBarTitle}
        />
      )}
      {showModal && <RegisterForm modal={showModal} setModal={setShowModal} />}
    </Layout>
  );
};

export default Page;
