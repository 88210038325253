// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var wrapperQuestion = "QuestionBlock-module--wrapperQuestion--1r9fE";
export var fullWidth = "QuestionBlock-module--fullWidth--ykQo9";
export var question = "QuestionBlock-module--question--1dAhY";
export var questionText = "QuestionBlock-module--questionText--3XgTc";
export var fa = "QuestionBlock-module--fa--36Zgd";
export var hr = "QuestionBlock-module--hr--1aVDN";
export var answer = "QuestionBlock-module--answer--1HwVU";
export var open = "QuestionBlock-module--open--1QG05";