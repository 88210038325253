import React from "react";
import * as styles from "./SectionCompliance.module.scss";
import SectionComplianceI from "./SectionCompliance.type";

const SectionCompliance = ({
  complianceTitle,
  description,
}: SectionComplianceI) => {
  return (
    <div className={styles.element}>
      <h3 className={styles.title}>
        {complianceTitle.first_title}
        <span className={styles.middleTitle}>
          {complianceTitle.middle_title}
        </span>
        {complianceTitle.last_title}
      </h3>
      <p className={styles.description}>{description}</p>
    </div>
  );
};

export default SectionCompliance;
