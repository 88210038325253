import React from "react";
import * as styles from "./TileBuy.module.scss";
import TileBuyI from "./TileBuy.type";
import CheckIcon from "../../assets/icons/check.svg";
import Button from "../Button/Button";
import { cn } from "../../utils";

const TileBuy = ({
  title,
  price,
  discountText,
  patch,
  sellingPoints,
  className,
  setModal,
}: TileBuyI) => {
  return (
    <div className={cn([styles.element, className ? className : ""])}>
      <div className={styles.elementTop}>
        <h4 className={styles.title}>{title}</h4>
        <p>
          <span className={styles.price}>
            <sup className={styles.sup}>$</sup>
            {price}
          </span>{" "}
          <span className={styles.path}>{patch}</span>
        </p>
        {discountText && <p className={styles.discount}>{discountText}</p>}
      </div>
      <div className={styles.elementBottom}>
        {sellingPoints.map((item, index) => (
          <p key={index} className={styles.list}>
            <img className={styles.icon} src={CheckIcon} alt={"check icon"} />
            <span>{item.item}</span>
          </p>
        ))}
        <div className={styles.buttons}>
          <Button
            className={styles.button}
            text={"Talk to us"}
            size={"full"}
            color={"peach"}
            contactButton={true}
          />
          <Button
            className={styles.button}
            text={"Get started"}
            size={"full"}
            color={"peach"}
            url={"https://client.via.work/sign-up/"}
            contactButton={false}
          />
        </div>
      </div>
    </div>
  );
};

export default TileBuy;
