import React from "react";
import NextArrowI from "./NextArrow.type";
import { cn } from "../../../../utils";
import * as styles from "./NextArrow.module.scss";

const NextArrow = ({ className, onClick }: NextArrowI) => {
  return (
    <div
      className={cn([styles.element, className ? className : ""])}
      onClick={onClick}
    />
  );
};

export default NextArrow;
