import React from "react";
import * as styles from "./SectionQuote.module.scss";
import SectionQuoteI from "./SectionQuote.type";
import HeaderTitle from "../../HeaderTitle/HeaderTitle";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { GatsbyImage } from "gatsby-plugin-image";

const SectionQuote = ({ image, quote, name, role, mission }: SectionQuoteI) => {
  return (
    <>
      <div className={styles.element}>
        <div className={styles.wrapperImg}>
          <GatsbyImage
            className={styles.img}
            image={image.gatsbyImageData}
            alt={image.title}
          />
        </div>
        <div className={styles.content}>
          <p className={styles.quote}>{renderRichText(quote)}</p>
          <p className={styles.name}>{name}</p>
          <p className={styles.role}>{role}</p>
        </div>
      </div>
      <div className={styles.mission}>
        <HeaderTitle
          type={"section"}
          title={mission.title}
          secondTitle={mission.second_title}
          titleColor={mission.title_color}
          secondTitleColor={mission.second_title_color}
          classNameTitle={styles.title}
        />
      </div>
    </>
  );
};

export default SectionQuote;
