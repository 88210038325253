import React from "react";
import SectionSeoCopyI from "./SectionSeoCopyI.type";
import * as styles from "./SectionSeoCopy.module.scss";
import { GatsbyImage } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { cn } from "../../../utils";

const SectionSeoCopy = ({ copy, copyTitle }: SectionSeoCopyI) => {
  return (
    <>
      {copyTitle && (<div className={styles.seo_copy_title}><h2>{copyTitle}</h2></div>)}
      <div className={styles.seo_copy}>
        {copy &&
          copy.map((myCopy, index) => {
            return (
              <div key={index} className={cn([styles.grid_item, !myCopy.rich_text ? styles.displayNone : ""])}>
                <div className={styles.inner_content}>
                  {myCopy.image && (
                    <div className={styles.image_text}>
                      <GatsbyImage
                        image={myCopy.image.gatsbyImageData}
                        alt={myCopy.image.title}
                        className={styles.img}
                      />
                    </div>
                  )}
                  {myCopy.h2_title_text && (
                    <h2>
                      {myCopy.h2_title_text}
                      &nbsp;<span>{myCopy.h2_title_highlighted_text}</span>
                    </h2>
                  )}
                  {myCopy.rich_text && renderRichText(myCopy.rich_text)}
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default SectionSeoCopy;
