import React from 'react';
import ContactForm from '../../Scripts/Scripts/ContactForm';
import SectionFormI from './SectionFormI.type';
import * as styles from './SectionForm.module.scss';
import SectionTitle from '../SectionTitle/SectionTitle';

const SectionForm = ({ hubspotFormId, internalLinkId, title }: SectionFormI) => {
    return (
        <div className={styles.formContainer}>
            <SectionTitle {...title} />
            <ContactForm formId={hubspotFormId} targetId={internalLinkId} />
        </div>
    );
    }

export default SectionForm;
