// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var element = "TileBuy-module--element--3bJnI";
export var elementTop = "TileBuy-module--elementTop--M3DhF";
export var elementBottom = "TileBuy-module--elementBottom--2T3H8";
export var title = "TileBuy-module--title--Uz-pj";
export var discount = "TileBuy-module--discount--VAJOg";
export var list = "TileBuy-module--list--oD6u4";
export var icon = "TileBuy-module--icon--34mOn";
export var price = "TileBuy-module--price--3apae";
export var path = "TileBuy-module--path--3bDyL";
export var sup = "TileBuy-module--sup--1Zbu2";
export var buttons = "TileBuy-module--buttons--3fzIS";
export var button = "TileBuy-module--button--lq5ec";