// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var element = "SectionPolicy-module--element--3egiZ";
export var content = "SectionPolicy-module--content--3xCZ3";
export var block = "SectionPolicy-module--block--2kr-P";
export var subtitle = "SectionPolicy-module--subtitle--6yFGJ";
export var disc = "SectionPolicy-module--disc--26MMX";
export var roman_numbers = "SectionPolicy-module--roman_numbers--25-Zx";
export var letters = "SectionPolicy-module--letters--31cVc";
export var numbers = "SectionPolicy-module--numbers--5D335";