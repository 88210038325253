// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var element = "SectionLocals-module--element--1Uhcc";
export var content = "SectionLocals-module--content--1Gh3R";
export var subtitle = "SectionLocals-module--subtitle--3bAr4";
export var details = "SectionLocals-module--details--NeKtc";
export var text = "SectionLocals-module--text--3bdLU";
export var description = "SectionLocals-module--description--2EBxZ";
export var screen = "SectionLocals-module--screen--2Nm25";
export var item = "SectionLocals-module--item--dyNZd";