// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var element = "SectionCountries-module--element--12lbA";
export var search = "SectionCountries-module--search--11dvd";
export var inputSearch = "SectionCountries-module--inputSearch--2_Wbr";
export var button = "SectionCountries-module--button--3Z1w4";
export var searchResult = "SectionCountries-module--searchResult--HfS2l";
export var searchResultField = "SectionCountries-module--searchResultField--1laZE";
export var iconCountry = "SectionCountries-module--iconCountry--J4ZTz";
export var nameCountry = "SectionCountries-module--nameCountry--2HhGS";
export var open = "SectionCountries-module--open--1ZxhH";
export var content = "SectionCountries-module--content--2sHiY";