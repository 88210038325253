import React, { useEffect, useState } from "react";
import * as styles from "./SectionPositions.module.scss";
import HeaderTitle from "../../HeaderTitle/HeaderTitle";
import SectionPositionsI from "./SectionPositions.type";
import Button from "../../Button/Button";
import TileBlock from "../../TileBlock/TileBlock";
import JobItemI from "../../../types/JobItemI.type";

const SectionPositions = ({ title, button }: SectionPositionsI) => {
  const [jobs, setJobs] = useState<JobItemI[]>([]);
  useEffect(() => {
    (async () => {
      try {
        const items: JobItemI[] = [];
        const response = await fetch(
          `https://api.lever.co/v0/postings/via?mode=json`
        );
        const json = await response.json();
        json.map((item: any) => {
          const job: JobItemI = {
            hostedUrl: item.hostedUrl,
            industry: item.categories.team,
            type: item.text,
            region: item.categories.location,
          };
          items.push(job);
        });
        setJobs(items);
      } catch (err) {}
    })();
  }, []);
  return (
    <div className={styles.element}>
      <HeaderTitle
        type={"section"}
        title={title.title}
        secondTitle={title.second_title}
        titleColor={title.title_color}
        secondTitleColor={title.second_title_color}
        center={true}
        text={title.description}
        secondLine={title.second_line}
      />
      <div className={styles.positions}>
        {jobs &&
          jobs
            .slice(-4)
            .map((item, index) => <TileBlock key={index} item={item} />)}
      </div>
      <div className={styles.buttons}>
        {button && (
          <Button
            text={button.button_text}
            color="dark"
            size={"large"}
            url={button.url}
          />
        )}
      </div>
    </div>
  );
};

export default SectionPositions;
