// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var element = "SectionCompanies-module--element--2-LPl";
export var title = "SectionCompanies-module--title--12vp1";
export var description = "SectionCompanies-module--description--3touQ";
export var companies = "SectionCompanies-module--companies--18kNr";
export var wrapperImg = "SectionCompanies-module--wrapperImg--t2Xe-";
export var img = "SectionCompanies-module--img--3zdnp";